'use client';

import { ClientConfigProvider, IClientConfig } from '@spikemark/core';
import { SchoolTeam } from '@spikemark/rest-api';
import { TooltipProvider } from '@spikemark/ui/components/ui/tooltip';
import { AlertDialogProvider } from '@spikemark/ui/shell/alert/alert-dialog-provider';
import { GoogleAnalytics } from '@spikemark/ui/shell/google-analytics';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { FC, PropsWithChildren } from 'react';
import { TrackerScript } from './tracker';
export const Providers: FC<PropsWithChildren<{
  clientConfig: IClientConfig;
  session?: Session;
}>> = ({
  children,
  clientConfig,
  session
}) => {
  return <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <ClientConfigProvider value={clientConfig} data-sentry-element="ClientConfigProvider" data-sentry-source-file="providers.tsx">
        <GoogleAnalytics data-sentry-element="GoogleAnalytics" data-sentry-source-file="providers.tsx" />
        <TrackerScript apiKey={clientConfig.mixpanel.apiKey} data-sentry-element="TrackerScript" data-sentry-source-file="providers.tsx" />
        <AlertDialogProvider data-sentry-element="AlertDialogProvider" data-sentry-source-file="providers.tsx">
          <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-source-file="providers.tsx">{children}</TooltipProvider>
        </AlertDialogProvider>
      </ClientConfigProvider>
    </SessionProvider>;
};