import qs from 'qs';

export function getRequestHash(path: string, method: string, params?: object) {
  return JSON.stringify({
    path,
    method,
    params,
  });
}

export function getRequestUrl(path: string, params?: Record<string, unknown>) {
  if (params) {
    const query = qs.stringify(params, {
      skipNulls: true,
    });
    return query ? `${path}?${query}` : path;
  }

  return path;
}
