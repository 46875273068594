import { TRACKED_PROPERTIES } from '@/constants';
import { useFetch } from '@spikemark/shared-hooks';
import pick from 'lodash/pick';
import mixpanel from 'mixpanel-browser';
import { User } from 'next-auth';
import Script from 'next/script';
import { useEffect } from 'react';
export const TrackerScript = ({
  apiKey
}: {
  apiKey?: string;
}) => {
  const trackingEnabled = apiKey !== 'MixPanelApiKey';
  const {
    data: user
  } = useFetch<User>(`/api/user`);
  useEffect(() => {
    if (trackingEnabled) {
      mixpanel.identify(user?.userId ?? 'anonymous');
      mixpanel.people.set({
        [TRACKED_PROPERTIES.user.id]: user?.userId,
        [TRACKED_PROPERTIES.user.name]: user?.name,
        [TRACKED_PROPERTIES.user.roles]: user?.roles,
        [TRACKED_PROPERTIES.user.schools]: user?.schools?.map(school => pick(school, ['schoolId', 'schoolName', 'boardName', 'gender', 'division', 'conference']))
      });
    }
  }, [apiKey, trackingEnabled, user]);
  return <Script id="tracker-script" data-sentry-element="Script" data-sentry-component="TrackerScript" data-sentry-source-file="tracker.tsx">
      {mixpanel.init(trackingEnabled ? apiKey! : ' ', {
      track_pageview: true
    })}
    </Script>;
};
export { mixpanel as tracker };